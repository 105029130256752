import React, {useState, createRef, useRef } from "react";
import useOutsideAlerter from "./useOutsideAlerter";
import DependentGuidelineRow from "../dependentGuidelineRow";
import { createPopper } from "@popperjs/core";

const Tooltip = ({ matrix, chemical, guidelines }) => {
  const [tooltipShow, setTooltipShow] = useState(false);
  const btnRef = createRef();
  const tooltipRef = createRef();
  const wrapperRef = useRef(null);
  const dependentChemical = [...new Set(guidelines.map(guideline => guideline.dependent_chemical).filter(chemical => chemical !== null))][0]
  
  const popper = () => {
    return createPopper(btnRef.current, tooltipRef.current, {
      placement: "left",
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    });
  }

  const toggleTooltip = () => {
    popper()
    setTooltipShow(prevState => !prevState);
  };

  useOutsideAlerter(wrapperRef, () => setTooltipShow(false));

  return <>
    <div ref={wrapperRef}>
      <p className='cursor-pointer underline'
         onClick={toggleTooltip}
         ref={btnRef}
      >
        *varies
      </p>
      <div
        className={`w-60 text-sm border border-gray-400 text-gray-600 shadow-lg bg-gray-100 block break-words rounded-xl z-50 ${!tooltipShow && 'hidden'} h-36 overflow-auto`}
        ref={tooltipRef}
      >
        <div className='mb-2'>
          <p className='capitalize truncate'>{chemical.name}</p>
          <p className='capitalize'>{matrix} Guidelines</p>
          <p>{`varies with ${dependentChemical}`}</p>
        </div>
        <table className='mx-auto'>
          <thead className="sticky top-0">
            <tr>
              <th>{dependentChemical}</th>
              <th>Guideline</th>
            </tr>
          </thead>
          <tbody>
            {guidelines.map((guideline, i) => <DependentGuidelineRow key={i} guideline={guideline} />)}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

export default Tooltip
