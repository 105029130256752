import React from "react";
import UploadLog from "./uploadLog";
import UploadTable from "./uploadsComponents/uploadTable";
import LocationTable from "./uploadsComponents/locationTable";
import Attachments from "./uploadsComponents/attachments";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Uploads = ({site, siteUploads, samplesCount, siteAttachments, sampleLocations, siteLocationUploads}) => {
  const hasUploads = siteUploads.length > 0
  return (
    <>
      <ToastContainer/>
      <div className="flex justify-between mb-1">
      <div>
        <button className="btn btn-primary font-semibold" onClick={() =>  window.open(`/sites/${site.id}/custom_guidelines`, '_self', "noopener=true,noreferrer=100")}>Edit Custom Site Guidelines</button>
        <button className="btn btn-primary font-semibold" onClick={() =>  window.open(`/sites/${site.id}/edit`,'_self', "noopener=true,noreferrer=100")}>Edit Generic Site Guidelines</button>
      </div>
      <button className="btn btn-primary font-semibold" onClick={() =>  window.location.href = `/sites/${site.id}/site_uploads/new`}>Upload CSV</button>
      </div>
      { hasUploads && <UploadLog site_id={site.id} /> }
      <div className="grid grid-cols-4 gap-4 w-full">
        <div className="col-span-3">
          { hasUploads && <UploadTable site={site} siteUploads={siteUploads} samplesCount={samplesCount} /> }
          <LocationTable site={site} siteLocationUploads={siteLocationUploads}/>
        </div>
        <div className="col-span-1">
          <Attachments site={site} siteAttachments={siteAttachments} sampleLocations={sampleLocations} />
        </div>
      </div>
    </>
  );
};

export default Uploads;
