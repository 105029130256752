import React from "react";

const UploadCSVModal = () => {
  return (
    <>
      <div className='modal-container fixed inset-0 flex justify-end z-20'>
        <div className='modal-overlay absolute inset-0 bg-gray-900 opacity-50'></div>
        <div className='modal-content-container bg-white h-28 w-72 rounded shadow-lg z-50 overflow-y-auto flex justify-center items-center absolute left-60 top-36'>
          <div className='modal-content p-5'>
            <div className='flex flex-col justify-center items-center z-50'>
              <h3 className='text-lg font-semibold text-center'>No data to display</h3>
              <p className='text-sm mt-2'>Upload your first dataset!</p>
              <a className='border mt-1 p-1 bg-gray-500 rounded-md text-white hover:bg-gray-700 text-sm' href='/sites'>Back to sites</a>
            </div>
          </div>
        </div>
       <i className="fa fa-arrow-left text-2xl text-white z-20 absolute top-40 left-52 animate-bounce-sideways mr-2 mb-5" />
      </div>
    </>
  );
};

export default UploadCSVModal;
