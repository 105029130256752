import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'
import { buildReferenceChartOptions, buildAlkylatedReferenceChartOptions, titleBuilder } from '../helpers/chartHelpers'
import TabHeader from '../shared/tabHeader'
import ConcentrationChart from '../shared/concentrationChart'
import ContributionChart from '../shared/contributionChart'
import ExtendedConcentrationChart from '../shared/extendedConcentrationChart'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import ExportButton from './modal/exportButton'
import LabSelect from './labSelect'
import HelpLabels from './helpLabels'

const Charts = ({ selectedSample, site }) => {
  const { mode, subHeaderTab, labSource, sourceReferences } = usePahFingerprinting()
  const [references, setReferences] = useState(null)
  const [alkylatedReferences, setAlkylatedReferences] = useState(null)

  useEffect(() => {
    if (selectedSample && site) {
      if (mode === 'Alkylated') {
        axios.get(`/sites/${site?.id}/reports/double_ratio_plot/alkylated_pah_similarity?sample_id=${selectedSample?.id}&lab_source=${labSource}`)
          .then(res => setAlkylatedReferences(res.data))
          .catch(err => console.log(err))
      }

      if (mode === 'Parent') {
        axios.get(`/sites/${site?.id}/reports/double_ratio_plot/parent_pah_similarity?sample_id=${selectedSample?.id}&source_references=${sourceReferences}`)  
          .then(res => setReferences(res.data))
          .catch(err => console.log(err))
      }
    } else {
      setReferences(null)
      setAlkylatedReferences(null)
    }
  },[selectedSample, site, mode, labSource, sourceReferences])

  useEffect(() => {
    setAlkylatedReferences(null)
  }, [labSource])

  const concentrationChart =  (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id} className='sticky left-0 z-10 border-r-2'>
      <ConcentrationChart key={selectedSample?.id} sample={selectedSample} site={site} />
    </div>
  )

  const contributionChart = (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id} className='sticky left-0 z-10 border-r-2'>
      <ContributionChart key={selectedSample?.id} sample={selectedSample} site={site} />
    </div>
  )

  const extendedContributionChart = (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id} className='sticky left-0 z-10 border-r-2'>
      <ExtendedConcentrationChart key={selectedSample?.id} sample={selectedSample} site={site} labSource={labSource}/>
    </div>
  )

  const referenceConcentrationCharts = mode === 'Parent' &&  sourceReferences && references?.map(reference => {
    const title = `${reference?.source_type ? reference?.source_type + ' sample' : 'Top Reference Library Match'} </br> ${reference?.name} </br> ${reference?.similarity.toFixed(2)}% similarity to selected sample`
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildReferenceChartOptions(title, reference?.concentration_data, 'concentration', 'Concentration (mg/kg)')}
        />
      </div>
    )
  })

  const referenceContributionCharts = mode === 'Parent' && sourceReferences && references?.map(reference => {
    const title = titleBuilder(reference)
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildReferenceChartOptions(title, reference?.contribution_data, 'contribution', 'Contribution (%)')}
        />
      </div>
    )
  })

  const referenceExtendedContributionCharts = mode === 'Alkylated' && sourceReferences && alkylatedReferences?.map(reference => {
    const title = titleBuilder(reference)
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildAlkylatedReferenceChartOptions(title, 'Contribution (%)', reference?.contribution_data, false, labSource)}
        />
      </div>
    )
  })

  return  (
    <div className='w-full h-full mt-1 overflow-x-auto'>
      <div className='sticky left-0 flex justify-between items-center'>
        <div className='flex w-1/2 items-center'>
          <p className='font-semibold mx-1 text-sm'>{selectedSample.sample_name_for_json}</p>
          <ExportButton selectedSample={selectedSample}/>
          <HelpLabels label={'Add sample to preview'} direction={'left'}/>
        </div>
        <div className='flex justify-end items-center w-1/2 mx-1'>
          {mode === 'Parent' &&
            <>
              <HelpLabels label={'Toggle between % or mg/kg'} direction={'right'}/>
              <TabHeader title={'Concentration'} tab={subHeaderTab} headerField={'subHeaderTab'} tabNumber={1} />
              <TabHeader title={'Contribution'} tab={subHeaderTab} headerField={'subHeaderTab'} tabNumber={2} />
            </>
          }
          {mode === 'Alkylated' && (
            <>
              <HelpLabels label={'COGRAD: 48 parameters, SGS: 75 parameters'} direction={'right'}/>
              <LabSelect/>
            </>
          )}
        </div>
      </div>
      <div className='flex overflow-x-auto mt-1'>
        {mode === 'Parent' && subHeaderTab === 1 && <>{concentrationChart}{referenceConcentrationCharts}</>}
        {mode === 'Parent' && subHeaderTab === 2 && <>{contributionChart}{referenceContributionCharts}</>}
        {mode === 'Alkylated' && <>{extendedContributionChart}{referenceExtendedContributionCharts}</>}
        {!sourceReferences &&
            <div className='flex justify-center items-center w-full border-2 mx-1'>
              <p className='font-semibold'>Set key samples to display references</p>
            </div>
        }
      </div>
    </div>
  )
}

export default Charts
