import React, { useState, useEffect } from 'react';
import ChangedUnitRow from "../changedUnitRow";
import { useParameterSelectApi, useParameterSelectContext } from "../../../contexts/parameterSelection";
import axios from "axios";
import { toast } from "react-toastify";

const ParametersChangedModal = () => {
  const [parameters, setParameters] = useState([])
  const { parameterColumns, selectedUnits, unmappedParameters, showModal, groundwaterMetaData, changedParameters, matrix } = useParameterSelectContext()
  const { setShowModal } = useParameterSelectApi()

  useEffect(() => {
    setParameters(Object.values(changedParameters).filter(value => value))
  }, [changedParameters])

  const updateParameterColumns = () => {
    const url = window.location.href
    const updateURL = url.replace('parameter_selection', 'update_parameter_columns.json')
    axios.patch(updateURL, { parameter_columns: parameterColumns, units_columns: selectedUnits, unmapped_parameters: unmappedParameters }).then(res => {
      window.location.href= res.data.path
    })

    if (matrix.title == 'Groundwater' && Object.values(groundwaterMetaData).length > 0) {
      const url = window.location.href
      const updateURL = url.replace('parameter_selection', 'update_groundwater_meta_data.json')
      axios.patch(updateURL, { site_upload: groundwaterMetaData }).then(res => {
        console.log('Success')
      })
    }
  }

  const updateSiteUpload = () => {
    toast.success('Processing selection, please wait')
    updateParameterColumns()
    setShowModal(false)
  }

  const parametersList = parameters.map((parameter, index) => {
    const bottomBorder = index == parameters.length - 1 ? 'border-b' : ''
    return <ChangedUnitRow key={index} parameter={parameter} bottomBorder={bottomBorder} />
  });

  return (
    <>
      {showModal &&
        <div className='modal-container fixed inset-0 flex items-center justify-center z-50'>
          <div className='modal-overlay absolute inset-0 bg-gray-900 opacity-50'></div>
          <div className='modal-content-container bg-white w-2/5 rounded shadow-lg z-50 flex justify-center items-center'>
            <div className='modal-content p-5'>
              <div className='flex flex-col justify-center items-center text-center'>
                <h1 className='text-xl font-semibold'>Confirmation Required</h1>
                  {parameters.length > 0 ?
                    <>
                      <p className='mb-1'>The following parameters and/or units have been changed:</p>
                      <div className='overflow-y-auto max-h-80'>
                        <table className='table'>
                          <thead>
                          <tr>
                            <th className='text-left p-2'>Original Parameter</th>
                            <th className='text-left p-2'>New Parameter</th>
                            <th className='p-2'>Original Unit</th>
                            <th className='p-2'>New Unit</th>
                          </tr>
                          </thead>
                          <tbody>
                          {parametersList}
                          </tbody>
                        </table>
                      </div>
                      <p className='m-2 w-2/3 text-sm'>*Changing the parameter mapping and their respective units will
                        affect the guidelines calculated for each parameter an their respective sample values</p>
                    </>
                    :
                    <p className='text-sm my-2'>No parameters or units have been changed, please click continue to proceed</p>
                  }
                <div className='flex justify-center items-center'>
                  <button className='btn btn-primary' onClick={updateSiteUpload}>Continue</button>
                  <button className='btn btn-default' onClick={() => setShowModal(false)}>Abort</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ParametersChangedModal
