import React from 'react';

const Pagination = ({ tableInstance, sites }) => {
  const { page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, state, gotoPage } = tableInstance;
  const { pageSize, pageIndex } = state;
  const startEntry = pageIndex * pageSize + 1;
  const endEntry = pageIndex * pageSize + page.length;
  const totalEntries = sites.length;
  const totalPages = pageOptions.length;

  const handlePageChange = (e) => {
    const newPageIndex = parseInt(e.target.value, 10) - 1;
    if (!isNaN(newPageIndex) && newPageIndex >= 0 && newPageIndex < totalPages) {
      tableInstance.gotoPage(newPageIndex);
    }
  };

  return (
    <div className="flex items-center mx-2">
      <div className="mx-2">
        <p className="sv-label">
          Showing {startEntry} to {endEntry} of {totalEntries} sites
        </p>
      </div>
      <div className="flex items-center">
        <button className="rounded bg-gray-300 w-5 h-5 flex items-center justify-center" onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
         <i className='fa fa-chevron-left text-xs'/><i className='fa fa-chevron-left text-xs'/>
        </button>
        <button className="rounded bg-gray-300 w-5 h-5 flex items-center justify-center mx-1" onClick={previousPage} disabled={!canPreviousPage}>
          <i className='fa fa-chevron-left text-xs'/>
        </button>
        <span className="sv-label">Page</span>
        <input
          type="number"
          className="mx-1 text-center border sv-label w-8 h-5"
          value={pageIndex + 1}
          onChange={handlePageChange}
        />
        <span className="sv-label">of {totalPages}</span>
        <button className="rounded bg-gray-300 w-5 h-5 flex items-center justify-center mx-1" onClick={nextPage} disabled={!canNextPage}>
          <i className='fa fa-chevron-right text-xs'/>
        </button>
        <button className="rounded bg-gray-300 w-5 h-5 flex items-center justify-center" onClick={() => gotoPage(totalPages - 1)} disabled={pageIndex === totalPages - 1}>
          <i className='fa fa-chevron-right text-xs'/><i className='fa fa-chevron-right text-xs'/>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
