import React, {useState, useEffect} from "react";
import axios from "axios";

const UploadStatusLogger = ({site, samples_count, min_max_dates}) => {
  const [calculating, setCalculating] = useState(site.calculating)
  const [totalRow, setTotalRow] = useState()
  const [uploadSamplesCount, setUploadSamplesCount] = useState()
  const hasSamples = samples_count > 0
  // Get the total imported row count
  const checkUploadStatus = () => {
    axios.get(`/sites/${site.id}/uploads_log.json`, {params: {id: site.id}})
      .then((response) => {
        setTotalRow(response.data.site_uploads[0].row_count.total)
      })
      .catch((err) => console.log(err));
  };
  
  // Check the status of the calculation if still calculating and the lastest upload count
  const checkStatus = () => {
    axios.get(`/sites/${site.id}/site_info.json`, {params: {id: site.id}})
      .then((response) => {
        setUploadSamplesCount(response.data.latest_upload_samples_count)
        if (!response.data.site_info.calculating) {
          setCalculating(response.data.site_info.calculating)
          window.location.reload()
        } 
        setTimeout(checkStatus, 5000);
      })
      .catch((err) => console.log(err));
  };

  const redirectToUpload = () => {
    window.location.href = `/sites/${site.id}/uploads`
  }

  useEffect(() => {
    if (calculating) {checkStatus()}
    checkUploadStatus();
  }, []);

  return (   
    <div className="flex font-semibold text-xs text-gray-400 font-sans truncate content-center ml-2 mr-1 cursor-pointer hover:text-white" onClick={redirectToUpload}>
      {calculating && totalRow && uploadSamplesCount && (
        <>
        <div className="flex flex-col justify-center items-center cursor-pointer hover:underline text-xs text-gray-400">
          <p><i className="fa fa-spinner fa-spin text-md m-1 mr-2 text-gray-400"/>Processing:</p>
          <p>{uploadSamplesCount} / {totalRow} new samples uploaded</p>
          
        </div>
          
        </>
      )}
      {!calculating && hasSamples && (
        <>
          <div className="flex flex-col justify-center items-center cursor-pointer hover:underline text-xs text-gray-400">
            <p><i className="fa fa-cloud-upload-alt text-md m-1 mr-2 text-gray-400"/>{samples_count} samples</p>
            <p>{min_max_dates}</p>
          </div>
        </>
      )}
    </div>
  )
};

export default UploadStatusLogger
