import molecularLabels from "../../molecularLabels"

export const buildReferenceChartOptions = (title, data, type, yLabel, height = null) => {
  const unit = type === 'concentration' ? 'mg/kg' : '%'

  return {
    chart: {
      type: 'column',
      events: molecularLabels(65),
      height: height || 270,
      width: 450
    },
    title: {
      text: title,
      style: {
        fontSize: '12px',
        marginBottom: '50px',
        fontWeight: 'bold',
        color: 'gray'
      }
    },
    xAxis: {
      categories: data?.map(data => data.name),
      style: {
        fontSize: '11px'
      },
      plotLines: [{
        color: '#999999',
        width: 2,
        value: 5.5,
        dashStyle: 'dash'
      }],
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: yLabel,
      },
    },
    tooltip: {
      formatter: function() {
        return `${this.x}: ${this.y}${unit}`;
      }
    },
    legend: {
      enabled: false,
    },
    series: [{
      data: data?.map(data => {
        if (type === 'concentration') return data[type]
        if (type === 'contribution') return (data[type] * 100)
      }),
    }],
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  }
}

export const buildPahChart = (chartTitle, yAxisTitle, data, categories, unit, color, height = null) => {
  return {
    title: {
      text: chartTitle,
      style: {
        fontSize: '12px',
        marginBottom: '10px',
        fontWeight: 'bold'

      }
    },
    chart: {
      type: 'column',
      events: molecularLabels(),
      height: height || 270,
      width: 450,
      borderWidth: 1,
      borderColor: '#B0BEC5',
    },
    legend: {
      enabled: false
    },
    xAxis: {
      title: {
        text: 'Parameters',
        enabled: false
      },
      labels: {
        style: {
          fontSize: '11px'
        }
      },
      categories: categories,
      plotLines: [{
        color: '#999999',
        width: 2,
        value: 5.5,
        dashStyle: 'dash'
      }],
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: yAxisTitle
      },
      lineWidth: 0,
    },
    series: [
      {
        data: data,
        color: color || '#7cb5ec'
      }
    ],
    tooltip: {
      formatter: function() {
        return `${this.x}: ${this.y}${unit}`
      }
    },
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  }
}

export const sampleScatterData = (selectedSample, scatterData) => {
  const normalizeScatterData = scatterData.map(data => Math.min(data, 2))
  const changedValues = ['FLRN/PYR', 'IcdP/BghiP'].filter((_, index) => scatterData[index] !== normalizeScatterData[index])
  const additionalLabel = changedValues.length > 0 ? ` (${changedValues.join(', ')} > 2)` : '';
  
  return {
    name: selectedSample.sample_location.name,
    data: [normalizeScatterData],
    marker: {
      symbol: 'circle',
      radius: 5,
      fillColor: '#0095ED',
      lineColor: 'black',
      lineWidth: 1,
    },
    dataLabels: [{
      enabled: true,
      format: selectedSample.sample_location.name + additionalLabel,
      align: 'center',
      style: {
        textOutline: 'transparent',
      },
    }],
    showInLegend: false,
  }
}

export const referenceScatterPoints = (referenceScatterData) => {
  return {
    name: `${referenceScatterData.name} (${referenceScatterData.sourceType || 'Top Reference Library Match'})`,
    data: referenceScatterData.data,
    marker: {
      symbol: 'circle',
      radius: 5,
      lineColor: 'black',
      lineWidth: 1,
    },
    showInLegend: true,
  }
}

export const handleCogradData = (data) => {
  const parentData = data.find(item => item.name === 'Parent')
  const alkylatedData = data.find(item => item.name === 'Alkyl Homologs')
  const congenerData = data.filter(item => item.name !== 'Parent' && item.name !== 'Alkyl Homologs')

  const updatedAlkylatedData = {
    ...alkylatedData,
    data: alkylatedData.data.map((value, index) => value + congenerData.reduce((acc, item) => acc + item.data[index], 0))
  }

  return [parentData, updatedAlkylatedData]
}

export const buildAlkylatedReferenceChartOptions = (title, yAxisTitle, data, inPreview = false, labSource) => {
  const categories = ['NAP', 'C1-NAP', 'C2-NAP', 'C3-NAP', 'C4-NAP', 'ACE', 'C1-ACE', 'BPHY', 'C1-BPHY', 'C2-BPHY', 'FLR', 'C1-FLR', 'C2-FLR', 'C3-FLR', 'PHEN/ANT', 'C1-PHEN/ANT', 'C2-PHEN/ANT', 'C3-PHEN/ANT', 'C4-PHEN/ANT', 'DBT', 'C1-DBT', 'C2-DBT', 'C3-DBT', 'C4-DBT', 'FLRN/PYR', 'C1-FLRN/PYR', 'C2-FLRN/PYR', 'C3-FLRN/PYR', 'C4-FLRN/PYR', 'BaA/CHR', 'C1-BaA/CHR', 'C2-BaA/CHR', 'C3-BaA/CHR', 'C4-BaA/CHR', 'BFLRN/BPYR', 'C1-BFLRN/BPYR', 'C2-BFLRN/BPYR'];
  const alkylatedData = labSource === 'COGRAD' && data.length > 2 ? handleCogradData(data) : data
  
  return {
    chart: {
      type: 'column',
      height: inPreview ? 370 : 270,
      width: 600
    },
    title: {
      text: title,
      useHTML: true,
      style: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: inPreview ? '15px' : '10px',
        color: 'gray'
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      tickInterval: 2,
      lineWidth: 0,
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -90,
        style: {
          fontSize: inPreview ? '10px' : '8px',
        },
      },
      lineWidth: 0,
    },
    tooltip: {
      pointFormat: '{series.name}: {point.y} ' + '%',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    legend: {
      enabled: true,
      itemDistance: 0,
      itemMarginTop: 0,
      itemMarginBottom: 0,
      symbolHeight: 6,
      itemStyle: {
        fontSize: inPreview ? '10px' : '9px',
      },
    },
    series: alkylatedData,
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  };
};

export const buildAlkylatedChartOptions = (title, yAxisTitle, data, categories, totalConcentration, inPreview = false, labSource) => {
  const alkylatedData = labSource === 'COGRAD' && data ? handleCogradData(data) : data
  
  return {
    chart: {
      type: 'column',
      height: inPreview ? 370 : 270,
      width: 600,
      borderWidth: 1,
      borderColor: '#B0BEC5',
    },
    title: {
      text: `${title} </br> SUM PAH: ${totalConcentration} mg/Kg`,
      useHTML: true,
      style: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: inPreview ? '15px' : '10px',
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      tickInterval: 2,
      lineWidth: 0,
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -90,
        style: {
          fontSize: inPreview ? '10px' : '8px',
        },
      },
      lineWidth: 0,
    },
    tooltip: {
      pointFormat: '{series.name}: {point.y} ' + '%',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    legend: {
      enabled: true,
      itemDistance: 0,
      itemMarginTop: 0,
      itemMarginBottom: 0,
      symbolHeight: 6,
      itemStyle: {
        fontSize:  inPreview ? '10px' : '9px',
      },
    },
    series: alkylatedData,
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  };
};

export const titleBuilder = (reference) => {
  return `${reference?.source_type ? reference?.source_type + ' sample' : 'Top Reference Library Match'} </br> ${reference?.name} </br> ${reference?.similarity.toFixed(2)}% similarity to selected sample`
}
