export const getSoilGuidelineTitle = (data, matrix) => {
  const jurisdiction = data?.site?.jurisdiction
  const soilUsage = data?.site?.soil_usage
  const texture = data?.site?.texture || ''

  return `${jurisdiction} Guideline, ${soilUsage} Land Use, ${texture} ${matrix}`
}

export const getSubsoilGuidelineTitle = (data, matrix) => {
  const jurisdiction = data?.site?.jurisdiction
  const soilUsage = data?.site?.subsoil_usage
  const texture = data?.site?.texture || ''

  return `${jurisdiction} Guideline, ${soilUsage} Land Use, ${texture} ${matrix}`
}

export const getGWGuidelineTitle = (data, matrix) => {
  const jurisdiction = data?.site?.jurisdiction
  const groundwaterUsage = data?.site?.groundwater_usage
  const texture = data?.site?.texture || ''

  return `${jurisdiction} Guideline, ${groundwaterUsage} Land Use, ${texture} ${matrix}`
}

export const getDisplayValue = (guidelineValue) => {
  return guidelineValue?.guideline !== undefined ? (guidelineValue?.guideline !== 0 ? guidelineValue?.guideline : '0') : '-'
}

export const checkUnitMatch = (chemicalUnit, guidelineUnit) => {
  // For handling different µ representations
  const formattedUnits = guidelineUnit?.flat()?.map(unit => unit?.replace(/[μµ]/g, 'µ'))
  const formattedChemicalUnit = chemicalUnit?.replace(/[μµ]/g, 'µ')
  
  if (formattedUnits?.includes('µg/g')) formattedUnits.push('mg/kg')
  else if (formattedUnits?.includes('mg/kg')) formattedUnits.push('µg/g')

  return formattedUnits?.includes(formattedChemicalUnit)
}

export const findGuidelineByMatrix = (data, chemical, matrix) => {
  const guideline = data?.guidelines?.find((guideline) => guideline.chemical_id === chemical.id)
  return guideline?.matrix_guidelines?.find((guideline) => guideline.matrix === matrix)
}
