import React, { useEffect } from "react"
import axios from "axios"
import {
  getSoilGuidelineTitle,
  getGWGuidelineTitle,
  getSubsoilGuidelineTitle,
  checkUnitMatch,
  findGuidelineByMatrix
} from "./helpers/guidelinesDataRowHelpers"
import { useDataVaultContext, useDataVaultAPI } from "../../../contexts/dataVault"
import Tooltip from "../../dataVault/tooltip";

const GuidelineDataRows = ({chemicalHeaders, showGuidelines, hasUniqueIds}) => {
  const { guidelinesData, siteData } = useDataVaultContext()
  const { setGuidelinesData } = useDataVaultAPI()
  const columnSpan = hasUniqueIds ? 4 : 3
  const hasGuidelinesByMatrix = (matrix) => generateGuidelineRows(guidelinesData, chemicalHeaders, matrix).some((row) => row.props.children !== '-')
  
  useEffect(() => {
    // get site guidelines data after site data is loaded and not processing samples
    const cancelController = new AbortController()
    
    if (siteData?.calculating === false) {
      axios.get(`/sites/${siteData.site_info.id}/site_guidelines_data`, { signal: cancelController.signal })
        .then((response) => setGuidelinesData(response.data))
        .catch((error) => console.error(error))
    }
    
    return () => { 
      cancelController.abort()
      setGuidelinesData(null)
    }
  }, [siteData])
  
  const generateGuidelineRows = (data, chemicalHeaders, matrix) => {
    return chemicalHeaders?.map((chemical, i) => {
      const matrixGuideline = findGuidelineByMatrix(data, chemical, matrix)
      const rowClass = 'text-center text-xs font-semibold'
      if (matrixGuideline?.custom_guideline) return <td key={i} className={rowClass}>{matrixGuideline.custom_guideline.value}</td>

      if (Array.isArray(matrixGuideline?.guideline)) {
        const unit = [...new Set(matrixGuideline?.guideline?.map(guideline => guideline.unit_names || guideline.units))]
        const unitMatched = checkUnitMatch(chemical.unit, unit)
        const displayedValue = unitMatched ? <Tooltip matrix={matrix} chemical={chemical} guidelines={matrixGuideline?.guideline}/> : '-'
        
        return <td key={i} className={rowClass}>{displayedValue}</td>
      }
      else {
        const unit = matrixGuideline?.guideline?.unit_names || matrixGuideline?.guideline?.units
        const unitMatched = checkUnitMatch(chemical.unit, [unit])
        const displayedValue = matrixGuideline?.guideline?.guideline && unitMatched ? matrixGuideline?.guideline?.guideline : '-'
        return <td key={i} className={rowClass}>{displayedValue}</td>
      }
    })
  }

  return (
    <thead className={`sticky top-10 left-0 z-20`}>
      {showGuidelines &&
        <>
          {
            hasGuidelinesByMatrix('Soil') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getSoilGuidelineTitle(guidelinesData, 'Soil')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Soil')}
            </tr>
          }
          {
            hasGuidelinesByMatrix('Subsoil') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getSubsoilGuidelineTitle(guidelinesData, 'Subsoil')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Subsoil')}
            </tr>
          }
          {
            hasGuidelinesByMatrix('Groundwater') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getGWGuidelineTitle(guidelinesData, 'Groundwater')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Groundwater')}
            </tr>
          }
        </>
      }
    </thead>
  )
}

export default GuidelineDataRows
