import React, { useState } from "react"

const ChemicalHeader = ({ chemical }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleHeaderHover = () => setIsHovered(!isHovered)
  const maxwidth = isHovered ? "none" : "100px"

  return (
    <th
      className={`border-none py-1 px-2 uppercase cursor-default ${
        isHovered ? "" : "truncate ..."
      }`}
      style={{ maxWidth : maxwidth }}
      title={chemical.name}
      onMouseEnter={handleHeaderHover}
      onMouseLeave={handleHeaderHover}
    >
      {chemical.name}
      <br />
      <span className="normal-case text-gray-500">{chemical?.unit ? chemical.unit : '-'}</span>
    </th>
  )
}

export default ChemicalHeader
