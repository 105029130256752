import React, { useMemo } from "react";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";  
import SearchFilter from './tableComponents/searchFilter';
import Table from "./tableComponents/table";
import Pagination from "./tableComponents/pagination";
import { columns } from "./tableComponents/tableHelper";

const TableContainer = ({sites}) => {
  const data = useMemo(() => sites, []);
  const tableColumns = useMemo(() => columns, []);
  const tableInstance = useTable({ columns: tableColumns, data }, useGlobalFilter, useSortBy, usePagination);
  const { state, setGlobalFilter } = tableInstance;
  const { globalFilter } = state;
  
  return (
    <>
      <div className='flex justify-between items-center my-1'>
        <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <Pagination tableInstance={tableInstance} sites={sites}/>
      </div>
      <div className="h-1/3 overflow-auto">
        <Table tableInstance={tableInstance} />
      </div>
    </>
  )
};

export default TableContainer;
